import { render, staticRenderFns } from "./HeroDetailpage.vue?vue&type=template&id=2c996f8e&scoped=true"
import script from "./HeroDetailpage.vue?vue&type=script&lang=js"
export * from "./HeroDetailpage.vue?vue&type=script&lang=js"
import style0 from "./HeroDetailpage.vue?vue&type=style&index=0&id=2c996f8e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c996f8e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Subtitle: require('/vercel/path0/components/general/Subtitle.vue').default,AppButton: require('/vercel/path0/components/general/AppButton.vue').default,Container: require('/vercel/path0/components/general/Container.vue').default})
